import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          date: "2022年09月26日",
          label: "ザ ロイヤルパーク キャンバス 札幌大通公園",
          title: (
            <>
              【三者対談】北海道を体感する、「食」から見えてくる究極の地産地消
            </>
          ),
          img: {
            src: "/assets/images/journal/article05/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article05/kv__sp.png",
          },
        }}
      />
      <div className="l_sect u_pt0">
        <LWrap size="small">
          <p></p>
          <CJournalLead
            lead={
              <>
                北海道と聞くと何を思い浮かべるだろう。どこまでも広がる海と雄大な自然、そして豊かで肥沃な大地。そこから享受する海産物や畜産物、野菜など「食」を想起する人も多いはずだ。今回はそんな北海道に昨年開業したホテルで提供されている「食」を軸に、生産者、シェフ、ホテル総支配人の3名が対談。北海道の食の魅力と表現、SDGsの取り組み、そして地産地消など連携し合う三者それぞれの想いを語ってもらった。
              </>
            }
            annotation={[
              <>
                撮影時のみ、撮影対象者間の距離を確保した上で一部マスクを外して撮影しております。
              </>,
            ]}
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article05/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article05/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>豊かな土地、</span>
                <span>北海道産食材の持つ魅力</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CQuoteBox>
            <p>
              札幌市内から車を走らせること約40分。都会的な街の景色が徐々に変わり、青々とした緑とどこまでも続く畑、北海道らしいのんびりとした風景が広がる。
            </p>
            <p>到着したのは江別市の農業法人、アンビシャスファームだ。</p>
            <p>
              「北海道を体感する」というコンセプトのもと、2021年10月にオープンしたザ
              ロイヤルパーク キャンバス
              札幌大通公園の神田総支配人と、ホテル内のレストラン「HOKKAIDO
              CUISINE
              KAMUY」の橋本シェフパティシエが、農業の新しい可能性を追求するアンビシャスファーム代表の柏村氏に、北海道の食の魅力について聞いた。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>柏村さんは、移住されてから農業を始められたんですよね。</>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    大学は北海道だったのですが、広島で就職して10年前に江別に戻りました。江別が好きだったということもありましたし、自然に囲まれて暮らしたいという想いからこの地で農業を始めました。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    僕も移住者ですが北海道は魅力が多いですよね。何を食べても美味しいです。
                  </>
                ),
              },
              {
                label: "神田",
                text: (
                  <>
                    四季の移り変わりや豊かな自然から生み出される食材を日常的に食べられるのは幸せですよね。最近ではナチュールワインなどにも注目が集まっていて。これだけ食材が豊富なのは、土地がよいというのがやはり理由なんでしょうね。
                  </>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    土壌がよいこともありますし、寒暖の差が激しいという気候も食材を美味しくしている理由でしょうね。野菜も本当によく育ちます。あとは、食材の背景にしっかりとしたストーリーがあるのも、魅力と言えるんじゃないかな。生産者さんそれぞれの背景や想いで野菜に個性が出ますし、人を通じて見える物語があります。
                  </>
                ),
              },
            ]}
          />
          <CProfileMedia
            reverse={false}
            image={{
              img: {
                src: "/assets/images/journal/article05/img_chapter01_01.png",
              },
              imgSp: {
                src: "/assets/images/journal/article05/img_chapter01_01__sp.png",
              },
            }}
            alt={"アンビシャスファーム 代表取締役 柏村 章夫 氏"}
            company={<>アンビシャスファーム</>}
            officer={<>代表取締役</>}
            name={<>柏村 章夫</>}
            honor={"氏"}
            text={
              <>
                「新しい農業の可能性を創造し、人々を幸せにする」を企業理念に、作る人と食べる人をつなぐ「二人のマルシェ」など次世代につなぐ活動にも従事している
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "橋本",
                text: (
                  <>
                    KAMUYでも“人が見える”という部分は食材選びの際に、大事にしています。なんでも良いというわけではなく、その土地の食材に対する想いやビジョンがある生産者さんと積極的にお付き合いしていきたい。さまざまな生産者さんから道産の食材を仕入れていますが、そんな中でアンビシャスファームさんとも繋がり、ちょうどお取引が始まったところなので、どんな料理にしようか今から楽しみです。
                  </>
                ),
              },
              {
                label: "神田",
                text: <>ちなみに、どんな野菜を育てているんですか。</>,
              },
              {
                label: "柏村",
                text: (
                  <>
                    じゃがいもやトウモロコシ、アスパラガスなどの北海道を代表する野菜、それから江別の特産品であるブロッコリー。あとは、ロマネスコやビーツ、セロリアックなどちょっと珍しい野菜も少量多品種で育てています。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    北海道の野菜は味がしっかりしていますよね。和食でもフレンチでもどんな料理にも合わせやすい。アンビシャスファームさんは、栽培方法もかなり工夫をされていると聞きましたが。
                  </>
                ),
              },
            ]}
          />
          <CProfileMedia
            reverse={true}
            image={{
              img: {
                src: "/assets/images/journal/article05/img_chapter01_02.png",
              },
              imgSp: {
                src: "/assets/images/journal/article05/img_chapter01_02__sp.png",
              },
            }}
            alt={"HOKKAIDO CUISINE KAMUY シェフパティシエ 橋本 将幸 氏"}
            company={<>HOKKAIDO CUISINE KAMUY</>}
            officer={<>シェフパティシエ</>}
            name={<>橋本 将幸</>}
            honor={"氏"}
            text={
              <>
                北海道らしさと季節を感じる一皿など、見た目からも楽しめるスイーツメニューを考案している
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "柏村",
                text: (
                  <>
                    栄養周期に合わせた管理方法で、生育ステージに合わせて追肥をしています。実は昔ながらの栽培方法なのですが、最新の土壌分析などとも組み合わせることで、必要な栄養を必要な時に与えるので、良い野菜を育てることができるんです。
                  </>
                ),
              },
              {
                label: "神田",
                text: (
                  <>
                    なんだか子育てに通じるところがありますね。全てに手をかけるのではなく、必要な時に手をさしのべるじゃないですけど（笑）。
                  </>
                ),
              },
            ]}
          />
          <CProfileMedia
            reverse={false}
            image={{
              img: {
                src: "/assets/images/journal/article05/img_chapter01_03.png",
              },
              imgSp: {
                src: "/assets/images/journal/article05/img_chapter01_03__sp.png",
              },
            }}
            alt={"アンビシャスファーム 代表取締役 柏村 章夫 氏"}
            company={<>ザ ロイヤルパーク キャンバス 札幌大通公園</>}
            officer={<>総支配人</>}
            name={<>神田 典子</>}
            honor={"氏"}
            text={
              <>
                2021年10月の開業当初より総支配人を務め、北海道の魅力をホテルで体験してもらうさまざまな企画に携わる
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "柏村",
                text: (
                  <>
                    本当にそうですね、たまに野菜のことを「うちの子たち」なんて呼ぶほどですから（笑）。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter01_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  土壌と栽培の工夫、それだけでは美味しい野菜は育たない。人々を魅了してやまない北海道の食の魅力、その根底には生産者の想いや背景、懸命に愛情をかける「人」の存在がある
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article05/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article05/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>良い素材を届ける、</span>
                <span>“ストーリー”へのこだわり</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CQuoteBox>
            <p>
              良い素材をそのまま美味しくお届けする。当たり前ともとれる考えだが、そこにもまた一工夫があるらしい。生産者から引き継いたバトンをいかにお客様に届けるか、その工夫に料理人の
              “ストーリー”へのこだわりが見えてくる。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    北海道にいらっしゃるお客様の多くは、食に対する期待値がかなり高いと感じます。ホテルとしても、その期待に応えられるよう土地の食材を活かしたメニューに力を入れていきたいと思っているのですが。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    KAMUYでは、既製品をできるだけ使わず、北海道の食材そのものの美味しさを引き出すことをポリシーにしています。朝、昼、夜とメニューの構成を変えて楽しめますし、キャンバスラウンジではスイーツなどまた違った料理を出しているので、さまざまな角度から北海道を体感してもらえるのではと思います。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter02_01.png",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    橋本さんはシェフパティシエとして普段スイーツを担当されていますけど、考案されるメニューが独創的でとても面白いです。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    なるべく季節感のあるもの、旬のものを使うようにしています。実はこれまで北海道の野菜を使用したスイーツメニューにもチャレンジしていて、じゃがいものモンブランや百合根を使ったパフェを提供したこともあります。
                  </>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    北海道の野菜は糖度が高いので、余分な砂糖を加える必要もなくてスイーツにもいいですよね。じゃがいものモンブラン、気になります！
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    あとはメニューリストにもちょっとしたこだわりがありまして、イラストを起用して産地や食材についての情報発信をしています。ただ食べるだけでなく、食材のストーリーを知ってもらうことで、より北海道を感じていただけるかなと。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter02_02.png",
              alt: "",
            }}
            caption={
              <>
                朝食メニューは全てに北海道産食材を使用。メニューに添えられたイラストや地図から産地に興味が湧いてくる
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "柏村",
                text: (
                  <>
                    僕らもマルシェでお客様にお渡しする「農家のレシピ」という取り組みをしています。変わった野菜を育てているということもあるので、食べ方を発信することで、まずは触れてもらう、手に取ってもらいたいなと思っています。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter02_03.png",
              alt: "",
            }}
            caption={
              <>
                「農家のレシピ」。その時期の旬の野菜を自分達で調理してオリジナルで食べ方の提案をしている
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    素晴らしい取り組みですね。ストーリーをいかに伝えるかということは大切。それでいうと、KAMUYのメニューはアイヌ文化のエッセンスもストーリーとして含んでいますよね。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    はい、北海道の先住民族アイヌの伝統的な料理のエッセンスを加えています。例えばメニューの中に「チタタプ」と言うものがあるのですが、アイヌ語で「我々がたくさん叩いたもの」を意味する料理法で朝食の人気メニューの一つです。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter02_04.png",
              alt: "",
            }}
            caption={
              <>
                いくらと鮭、北海道産長芋を使った「チタタプ」（朝食メニューはシーズンによって異なる）
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "柏村",
                text: (
                  <>
                    アイヌ料理は北海道に住んでいる僕らでもあまり触れることがないですよね。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    北海道には冬を越すために考え出された先人たちの知恵がたくさんありますが、特にアイヌの人の食糧保存法や食すことそのものへのありがたみには、気づかされます。今後はもっと北海道に寄せたメニュー展開も考えていきたいなと。
                  </>
                ),
              },
              {
                label: "神田",
                text: (
                  <>
                    文化的な側面からも、北海道を感じていただきたいですよね。あとはワインも特徴的ですよね。あれだけのナチュールワインの品揃えは、なかなか札幌市内でもないんじゃないでしょうか。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "橋本",
                text: (
                  <>
                    後志や余市、空知などのワイナリーから仕入れたナチュールワインを豊富に取り扱っています。メジャーなものから、レアなものまで幅広く。食材と一緒に道産のチーズやワインなどとのペアリングも楽しんでいただきたいですね。
                  </>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    思いもしないようなアレンジやペアリングをしてくれるのは、生産者にとってもとても嬉しいこと。僕らでは生み出せない食材の価値を、作り手の方が高めていただけるのは本望です。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article05/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article05/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>持続可能性を視野に入れ、</span>
                <span>それぞれが取り組むSDGs</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CQuoteBox>
            <p>
              今やSDGsへの取り組みはどんな業界であっても欠かせない話題だ。豊かな自然から食材が生まれ、その食材を料理人が調理する。またホテルという空間においても、環境に配慮されたステイであることはこれからのホテルの在り方だろう。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    みなさん、SDGsの取り組みについてはどんなことをされていますか。SDGsと言ってもいろいろありますが、レストランは特にフードロスなど積極的に取り組んでもらっていますよね。柏村さんにも改めて共有させてください。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    はい、まずは廃棄食材を無くそうというところから始めています。例えば、野菜はスーパーには並ばない規格外のものを仕入れて、生産者の方々の廃棄削減に貢献できるようにですとか、出汁を引いた後の昆布を調理して小鉢にアレンジしたりとか。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter03_01.png",
              alt: "",
            }}
            caption={
              <>
                仕込みに使う野菜は形がいびつなものなどを積極的に仕入れる。また、肉や魚、野菜の切れ端を活用したブイヨンやベジブロスなども活用している
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    アンビシャスファームさんはSDGsについてはどんなふうにお考えでしょうか。
                  </>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    農業は自然あっての仕事ですから、持続可能なあり方については常に考えていますね。必要以上のものは土地に入れない、環境的影響があると言われている農薬は使わない。ただ、一般的な知識ではなくて自分たちで調べて、考えて、しっかりと判断していくことを大切にしています。
                  </>
                ),
              },
            ]}
          />
          <CJournalMedia
            data={[
              {
                img: {
                  src: "/assets/images/journal/article05/img_chapter03_02.png",
                  alt: "",
                },
                imgSub: {
                  src: "/assets/images/journal/article05/img_chapter03_03.png",
                  alt: "",
                },
                interaction: [
                  {
                    label: "橋本",
                    text: (
                      <>
                        いいものを届けるには、やっぱり今後はそこも意識していかなきゃいけないですね。
                      </>
                    ),
                  },
                  {
                    label: "神田",
                    text: (
                      <>
                        お二人のお話では、余ったものをどう活用するのかという視点だったんですが、ホテルではまた違った視点でSDGsに取り組んでいます。例えば、今回のホテルには北海道産の木材をふんだんに使用しているんですが、木造建築は建物を作る際に、実際に建材として使っている木材より多くの端材が出るんです。
                      </>
                    ),
                  },
                  {
                    label: "橋本",
                    text: <>そんなに…！</>,
                  },
                  {
                    label: "神田",
                    text: (
                      <>
                        通常は燃やしてしまったり、放置されてしまったりしていることが多いようですが、ホテル内でその端材を活用しています。例えば、北海道産トドマツを使用したホテルオリジナルのウッドスピーカーを全客室に完備するなど。お客様にお部屋でレコードを楽しんでいただくという体験を通して楽しんでもらっています。
                      </>
                    ),
                  },
                ],
              },
              {
                img: {
                  src: "/assets/images/journal/article05/img_chapter03_04.png",
                  alt: "",
                },
                imgSub: {
                  src: "/assets/images/journal/article05/img_chapter03_05.png",
                  alt: "",
                },
                interaction: [
                  {
                    label: "柏村",
                    text: <>おもしろい取り組みですね。 </>,
                  },
                  {
                    label: "神田",
                    text: (
                      <>
                        あとはインテリアでもカラマツの端材をエントランスの外壁材に使ったり、館内の飾りやベンチの背もたれにも端材を活用したりしています。
                      </>
                    ),
                  },
                  {
                    label: "橋本",
                    text: (
                      <>
                        これだけ北海道の自然を感じる空間はなかなかないですよね。普段、レストランやキャンバスラウンジは通りますけど、客室にもそんなSDGsの取り組みがあったとは驚きです。
                      </>
                    ),
                  },
                ],
                orderText: (
                  <>
                    ホテルオリジナルのウッドスピーカー。客室にあるレコード機で音楽をかけると優しい音色が響き渡る
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter03_06.png",
              alt: "",
            }}
            caption={
              <>
                開放的なキャンバスラウンジ。緑が溢れるこの空間にも北海道産木材をふんだんに使用している
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "神田",
                text: (
                  <>
                    少しでも廃棄する物を少なくしていくこと。そして、元々が素敵な素材ですから、それをアップサイクルして新たな付加価値をつけてお客様にご提供する。そうすることで、もう一度楽しんでもらうという仕組みを取り入れています。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article05/img_chapter04.png",
            },
            imgSp: {
              src: "/assets/images/journal/article05/img_chapter04__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>北海道を体感する、</span>
                <span>その根底にある</span>
                <span>地産地消とは</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CQuoteBox>
            <p>
              「北海道を体感する」—。コンセプトの通りザ ロイヤルパーク
              キャンバス
              札幌大通公園には、北海道が散りばめられている。その根底にあるものが地産地消だ。三者それぞれが考える地産地消、そして今後目指すべき姿とは。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "橋本",
                text: (
                  <>
                    地産地消って本来あるべき姿だと思うんですよね。流通網が発達して、今やどこにいてもあらゆる地域の食材が手に入るようになりましたし、食べられるようになった。便利ではありますけど、じゃあ差別化はどこでするのかという悩みもあります。北海道にわざわざ来てもらう意味を改めて突き詰めると「地産地消」というところに回帰していくのかなと。
                  </>
                ),
              },
              {
                label: "神田",
                text: (
                  <>
                    まさにそうですよね。ホテルでも取り組むべきところはそこだと思います。体験としてここでしか感じられないものに光を当てる。北海道を体感して、持ち帰っていただきたい。そのためのストーリーもしっかり伝えていかないといけないですね。
                  </>
                ),
              },
              {
                label: "柏村",
                text: (
                  <>
                    コロナ禍で強く感じたんですが、やはり地域とのつながりは改めて重要だと気付かされました。物流なども止まってしまった時に、地域の方との交流は何よりも強いつながりだと思いますし、こういったホテルやレストランなど取引先の方々との連携も重要です。
                  </>
                ),
              },
              {
                label: "橋本",
                text: (
                  <>
                    僕たち料理人も、生産者の方々から受け取った食材をしっかり料理として発信していきたいです。北海道のいろんな地域の食材や伝統的な料理を体感してもらえるようストーリーを込めて。アンビシャスファームさんの野菜の魅力も、これからしっかりお届けさせてもらいます。
                  </>
                ),
              },
              {
                label: "柏村",
                text: <>ありがとうございます！楽しみにしています。</>,
              },
              {
                label: "神田",
                text: (
                  <>
                    今後も協力しながら北海道の魅力を発信していきたいですね。今日はありがとうございました。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article05/img_chapter04_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  目の前に運ばれる、料理。その裏側には、生産者、そしてシェフ、ホテル総支配人たちの想いが連鎖している。今回の対談を通して「食」の育む繋がりやストーリー、北海道の食の奥深さに触れることができた。ただホテルに泊まるだけにとどまらない、「北海道を体感する」そんな新しいステイや食体験をしに、足を運んでみてはどうだろうか。
                </>
              ),
            }}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray u_pt0">
        <CChapterHeading
          bg="gray"
          data={{
            img: {
              src: "/assets/images/journal/article05/img_introduction.png",
            },
            imgSp: {
              src: "/assets/images/journal/article05/img_introduction__sp.png",
            },
            title: (
              <>
                <span>ザ ロイヤルパーク </span>
                <span>キャンバス 札幌大通公園</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CWideImgMedia
            data={[
              {
                img: {
                  src: "/assets/images/journal/article05/img_introduction_01.png",
                  alt: "",
                },
                text: (
                  <>
                    2021年10月にオープンした国内初高層ハイブリッド木造ホテル。SDGsを意識しながら究極の地産地消を目指し、コンセプトである「北海道を体感する」を、さまざまな体験を通して提供している。植栽をふんだんに配したキャンバスラウンジは、目の前の大通り公園や街路樹に溶け込むような緑あふれる空間。客室やキャンバスラウンジ、外観に至るまでコンセプトが行き届いている。札幌の中心地で、五感をフルに活用した北海道を感じるステイが楽しめる。
                  </>
                ),
              },
            ]}
          />
          <CFloatingImg
            data={[
              {
                src: "/assets/images/journal/article05/img_introduction_02.png",
              },
              {
                src: "/assets/images/journal/article05/img_introduction_03.png",
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  キャンバスラウンジのクッションの一部には、北海道で回収された漁網をアップサイクルした布をクッションカバーとして使用
                </>
              ),
            }}
          />
          <CFloatingImg
            reverse={true}
            data={[
              {
                src: "/assets/images/journal/article05/img_introduction_04.png",
              },
              {
                src: "/assets/images/journal/article05/img_introduction_05.png",
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  「キャンバスピックアップ」。アメニティはお客様自身が必要なものを必要な分だけ持っていくシステム。環境に配慮されたアメニティからもSDGsを意識している
                </>
              ),
            }}
          />
          <CFloatingImg
            reverse={false}
            data={[
              {
                src: "/assets/images/journal/article05/img_introduction_06.png",
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ルーフトップからは札幌テレビ塔を目の前にする大パノラマが広がる。夜には焚き火バーがオープンし、まさに北海道を体感する夜が過ごせる
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2022年9月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">
            ザ ロイヤルパーク キャンバス 札幌大通公園
          </p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
